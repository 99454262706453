import { useEffect } from 'react';
import logo from './JunkPatrolLogo.png';
import truckIllustration from './Truck-Illustration-1-clear-01.png'
import './App.css';
import { Helmet } from 'react-helmet';

function App() {
  useEffect(() => {
    document.title = 'Junk Hauling and Removal | Junk Patrol';
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Junk Hauling and Removal | Junk Patrol</title>
        <link rel="canonical" href="https://www.junkpatrolca.com" />
      </Helmet>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Junk Patrol Logo" />
        <h1 className='outfit-text'>
          Wave goodbye to your stress 👋
        </h1>
        <h3>
          Proudly Serving the East Bay, Reno, and Sacramento!
        </h3>
        <p>Contact us: <a href="tel:+19164096879" className='phoneNumber'>916-409-6879</a></p>
        <a
          className="bookingButton outfit-text"
          href="https://web.archive.org/web/20231204122504/https://booking2.junkpatrolca.com/rocklin/"
        >
          Book Online
        </a>
        <img src={truckIllustration} className='truckImage' alt="Junk Patrol Dump Truck for Junk Hauling Services" />
        <p className='text-faded'>Junk Patrol is Northern California's Number 1 Junk Removal Specialists
        </p>
        <p className='text-faded'>
          Services We Offer: Junk Removal, Junk Hauling, Dump Runs, Demolition Services, Hoarder Cleanouts, Spa Removals, and nearly all Debris cleanup. Construction Debris, Yard Waste, Greenwaste, Furniture, Commercial, and Residential Bulky Waste. We Haul It All!
        </p>
        <p className='text-faded'>
          Areas We Service: Sacramento, Roseville, Rocklin, Lincoln, Loomis, Granite Bay, Auburn, Citrus Heights, Folsom, El Dorado Hills, Lake Tahoe, North Highlands, Antelope, Orangevale, Meadow Vista, Colfax, Pleasant Grove, Marysville, and all surrounding areas.</p>
        <p className='textCaption mt-10'>(Our main website is undergoing maintenance)</p>
      </header>
    </div>
  );
}

export default App;
